@mixin text-style {
    color: #0E1726;
    font-family: "Nunito";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}



.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-self: stretch;

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        .breadcrumb {
            display: flex;
            gap: 7px;
            @include text-style;

            .non_active_page {
                color: #1570EF;
            }
        }
    }

    .cards_wrapper {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 24px;
        flex-wrap: wrap;

        .card {
            display: flex;
            padding: 20px;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            flex: 1 0 0;
            align-self: stretch;

            border-radius: 6px;
            background: linear-gradient(90deg, #3B82F6 0%, #60A5FA 100%);
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10);

            button {
                padding: 0px
            }

            .card_top {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                align-self: stretch;

                .text {
                    @include text-style;
                    color: #FFF;
                    font-weight: 600;
                }
            }

            .card_body {
                display: flex;
                gap: 12px;
                align-items: center;
                align-self: stretch;

                .text {
                    @include text-style;
                    color: #FFF;
                    font-size: 30px;
                    font-weight: 700;
                    line-height: 36px;
                }

                .squre_box {
                    // layout
                    display: flex;
                    padding: 2.8px 8.8px;
                    flex-direction: column;
                    align-items: flex-start;
                    border-radius: 4px;
                    background: rgba(255, 255, 255, 0.30);

                    // text
                    @include text-style;
                    color: #FFF;
                    font-size: 12px;
                    line-height: 16px;
                }
            }


            .card_footer {
                // leyout
                display: flex;
                padding-right: 80.31px;
                align-items: center;
                align-self: stretch;
                gap: 5px;

                .text {
                    width: max-content;

                    // text
                    @include text-style;
                    color: #FFF;
                    font-weight: 600;
                }
            }
        }

    }

    .two_chart_row {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;
        flex-wrap: wrap;

        .two_chart_row_card {
            display: flex;
            padding: 20px;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            flex: 1 0 0;
            border-radius: 6px;
            background: #FFF;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10);

            .card_top {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                align-self: stretch;

                .title {
                    @include text-style;
                    color: #515365;
                    font-family: "Plus Jakarta Sans";
                    font-weight: 700;
                }

                .number {

                    color: #F8538D;
                    font-family: "Plus Jakarta Sans";
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px;
                }
            }

            .card_body {
                width: 100%;
                max-width: 100%;
                transition: max-width 0.3s ease;

                canvas {
                    width: 100% !important;
                }
            }

            .card_footer {
                .ratio {
                    color: #00AB55;
                    font-family: "Plus Jakarta Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                }
            }
        }
    }

    .total_send_card {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;

        height: 101px;
        padding: 20px;
        gap: 16px;
        border-radius: 6px;
        background: linear-gradient(0deg, #00C6FB 0%, #005BEA 100%);
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10);


        color: #E0E6ED;
        font-family: "Plus Jakarta Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;

        /* 155.556% */
        .left_container {
            display: flex;
            justify-content: end;
            position: relative;

            .values_container {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 4px;

                .amount {
                    color: #FFF;
                    font-family: "Plus Jakarta Sans";
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 28px;
                }

                .spent_ratio {
                    padding: 4px;
                    border-radius: 4px;
                    background: #4361EE;
                    width: fit-content;

                    // text
                    color: #FFF;
                    font-family: Nunito;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                }
            }

            .pattern {
                position: absolute;
                right: -350px;
                bottom: -391.8px;
                width: 803px;
                height: 583px;
                border-radius: 9999px;
                background: #1937CC;
            }
        }
    }

    .three_chart_row_container {
        display: flex;
        padding-right: 0.01px;
        justify-content: center;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
        flex-wrap: wrap;

        .three_charts_row_card {
            display: flex;
            padding: 20px;
            flex-direction: column;
            align-items: flex-start;
            gap: 39.6px;
            flex: 1 0 0;
            align-self: stretch;

            border-radius: 6px;
            background: #FFF;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10);

            .card_top {
                display: flex;
                align-items: center;
                align-self: stretch;

                .circle {
                    border-radius: 50%;
                    flex-shrink: 0;
                    background-color: #ECF2FF;
                }

                .title_wrapper {
                    display: flex;
                    padding-left: 8px;
                    flex-direction: column;
                    align-items: flex-start;


                    // common text
                    font-family: "Plus Jakarta Sans";
                    font-style: normal;
                    font-weight: 600;
                    line-height: 16px;

                    .title {
                        color: #3B3F5C;
                        font-family: "Plus Jakarta Sans";
                        font-size: 14px;
                    }

                    .subTitle {
                        color: #888EA8;
                        font-size: 12px;
                    }
                }
            }

            .card_body {

                width: 100%;
                max-width: 100%;
                transition: max-width 0.3s ease;

                canvas {
                    width: 100% !important;
                }
            }

            .card_footer {
                display: flex;
                padding-bottom: 0.4px;
                justify-content: space-between;
                align-items: center;
                align-self: stretch;

                .amount {
                    color: #0E1726;
                    font-family: "Plus Jakarta Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                }

                .ratio {
                    font-family: "Plus Jakarta Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }
    }

    .total_spend_chart_container {
        display: flex;
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        flex: 1 0 0;
        align-self: stretch;


        border-radius: 6px;
        background: #FFF;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10);

        .card_top {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            align-self: stretch;

            .heading {
                color: #0E1726;
                font-family: Nunito;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
            }
        }

        .subHeading_container {
            display: flex;
            padding-bottom: 0px;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;

            .subHeading_wrapper {
                display: flex;
                align-items: center;
                gap: 10px;

                .subHeading {
                    color: #0E1726;
                    font-family: Nunito;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 28px;
                }

                .amount {
                    color: #4361EE;
                    font-family: Nunito;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 28px;
                }
            }

            .chart_labels {
                display: flex;
                gap: 24px;

                .text {
                    color: #5A607F;
                    font-family: "Plus Jakarta Sans";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                }

                .lebel_container {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }

                .squre_box_blue {
                    width: 12px;
                    height: 12px;
                    border-radius: 2px;
                    background: #1B55E2;
                }

                .squre_box_red {
                    width: 12px;
                    height: 12px;
                    border-radius: 2px;
                    background: #E7515A;
                }

                .squre_box_green {
                    width: 12px;
                    height: 12px;
                    border-radius: 2px;
                    background: #00AB55;
                }

            }
        }

        .chart {

            display: flex;

            align-self: stretch;
            width: 100%;
            max-width: 100%;
            transition: max-width 0.3s ease;

            canvas {
                display: flex !important;
                align-self: stretch !important;
                width: 100% !important;
                height: 50% !important;
                // width: -webkit-fill-available !important;
            }
        }



    }
}