.container {
  display: flex;
  padding: 60px 40px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 100px;
  flex: 1 0 0;
  .head {
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      width: 60.012px;
      height: 55.233px;
      flex-shrink: 0;
    }
    .text {
      color: #000;
      font-family: Nunito;
      font-size: 31.541px;
      font-style: normal;
      font-weight: 300;
      line-height: 45.058px; /* 142.857% */
      margin-left: 10px;
    }
  }

  .content {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10%;
    row-gap: 10px;
    width: 100%;
    .errorText {
      color: red;
      margin-top: -15px;
      font-size: 0.8rem; /* Adjust font size as needed */
    }

    .text {
      color: var(--Colors-Style-Neutrals-N900, #091e42);
      text-align: center;

      /* Display md/Semibold */
      font-family: Nunito;
      font-size: 36px;
      font-style: normal;
      font-weight: 800;
      line-height: 44px; /* 122.222% */
      letter-spacing: -0.72px;
    }
    .description {
      color: var(--Gray-cool-600, #4a5578);
      text-align: center;

      /* Text xl/Regular */
      font-family: Nunito;
      font-size: 20px;
      font-style: normal;
      font-weight: 350;
      line-height: 30px; /* 150% */
      width: 70%;
    }

    .fields {
      margin-top: 5%;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      width: 100%;
      .eyeIcon {
        cursor: pointer; /* Change cursor to pointer on hover for better UX */
        width: 20px; /* Adjust width as needed */
        height: auto; /* Maintain aspect ratio */
        margin-left: -40px; /* Adjust the margin to position the eye icon properly */
        padding: 10px; /* Add padding for better clickability */
      }

      .icon {
        display: flex;
        padding: 8.4px 12px 9.2px 12.8px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 6px 0px 0px 6px;
        border-top: 0.8px solid #e0e6ed;
        border-bottom: 0.8px solid #e0e6ed;
        border-left: 0.8px solid #e0e6ed;
        background: #eee;
      }
      .field {
        display: flex;
        padding: 10px 16px;
        flex-direction: column;
        border-radius: 0px 6px 6px 0px;
        border: 1px solid #e0e6ed;
        background: #fff;
        width: 100%;
        color: var(--input-placeholder-color, #6b7280);
        font-family: "Plus Jakarta Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        outline: none;

        &:focus{
          border: 1px solid var(--Blue-600, #1570EF);
        }
      }
    }

    .remember_me {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 3%;
      .remember {
        display: flex;
        align-items: center;
        gap: 4px;

        .checkBox {
          width: 18px;
          height: 18px;
          fill: var(--Other-Clickable, rgba(255, 255, 255, 0));
        }
        .text {
          color: var(--Colors-Style-Neutrals-N900, #091e42);

          /* Type Style (main)/Label 1/Regular */
          font-family: "Plus Jakarta Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
        }
      }

      .forgot_password {
        color: var(--Colors-Style-Key-Colors-Blue, #0057d9);
        cursor: pointer;
        /* Type Style (main)/Label 1/Regular */
        font-family: "Plus Jakarta Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
      }
    }

    .signInBtn {
      width: 100%;
      display: flex;
      padding: 10px 18px;
      justify-content: center;
      border-radius: 8px;
      margin-top: 48px;
      border: 1px solid var(--Blue-600, #1570ef);
      background: var(--Blue-600, #1570ef);
      cursor: pointer;
      /* button/shadow */
      box-shadow: 0px 10px 20px -10px rgba(67, 97, 238, 0.6);

      color: var(--Base-White, #fff);

      /* Text md/Semibold */
      font-family: Nunito;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 24px; /* 150% */
    }

    .create_account {
      display: flex;
      justify-content: center;
      margin-top: 3%;
      .text {
        color: var(--Colors-Style-Key-Colors-Blue, #0057d9);

        /* Type Style (main)/Label 1/Medium */
        font-family: "Plus Jakarta Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        margin-left: 5px;
        cursor: pointer;
      }
      .sign_up {
        color: var(--Colors-Style-Neutrals-N100, #7a8699);

        /* Type Style (main)/Label 1/Medium */
        font-family: "Plus Jakarta Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
      }
    }
  }
}
