.container {
  display: flex;
  padding: 60px 0px 75px 112px;
  align-items: flex-end;
  background: var(--Gray-blue-50, #f8f9fc);

  .heading {
    display: flex;
    max-width: 627px;
    flex-direction: column;
    align-items: flex-start;
    gap: 22px;

    .title {
      align-self: stretch;
      color: var(--Blue-700, #175cd3);
      font-family: "Plus Jakarta Sans";
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      @media only screen and (max-width: 600px) {
        font-size: 25px;
      }
     

      /* 57.6px */
    }

    .subTitle {
      align-self: stretch;
      color: var(--Gray-cool-700, #404968);

      font-family: "Plus Jakarta Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;

      @media only screen and (max-width: 600px) {
        padding-right: 15px;
      }
    }
  }

  .laptop {
    display: flex;
    padding-top: 48px;
    flex-direction: column;
    align-items: flex-end;

    // bottom: 75.56px;
    .img {
      max-width: 100%;

      // flex-shrink: 0;
      // fill: lightgray 0px 3.828px / 100% 180.53% no-repeat;
    }
  }
}

