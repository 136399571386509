.card {
  display: flex;
  padding: 20px;
  margin-top: 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);

  .fields {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;

    .field_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 10px;
      label {
        color: var(--Gray-700, #344054);
        font-family: "Plus Jakarta Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        width: 170px;
      }
      .eyeIcon {
        cursor: pointer; /* Change cursor to pointer on hover for better UX */
        width: 20px; /* Adjust width as needed */
        height: auto; /* Maintain aspect ratio */
        margin-left: -40px; /* Adjust the margin to position the eye icon properly */
        padding: 10px; /* Add padding for better clickability */
      }

      .icon {
        display: flex;
        padding: 8.4px 12px 9.2px 12.8px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 6px 0px 0px 6px;
        border-top: 0.8px solid #e0e6ed;
        border-bottom: 0.8px solid #e0e6ed;
        border-left: 0.8px solid #e0e6ed;
        background: #eee;
      }
      .field {
        display: flex;
        padding: 10px 16px;
        flex-direction: column;
        border-radius: 0px 6px 6px 0px;
        border: 1px solid #e0e6ed;
        background: #fff;
        color: var(--input-placeholder-color, #6b7280);
        font-family: "Plus Jakarta Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        outline: none;
        width: 100%;
        &:focus {
          border: 1px solid var(--Blue-600, #1570ef);
        }
      }
      .errorText {
        color: red;
        margin-top: 5px;
        font-size: 0.8rem; /* Adjust font size as needed */
      }
    }
    
    .hr{
        height: 1px;
        align-self: stretch;
        background: var(--Gray-200, #EAECF0);
    }
    .btns{
        display: flex;
        gap: 12px;
        align-items: flex-end;
        justify-content: flex-end;
    }
  }
}
