@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
:root {
  iframe#webpack-dev-server-client-overlay {
    display: none !important;
  }

  --tw-ring-offset-shadow: 0 0 #0000; /* Default fallback shadow (transparent black) */
  --tw-ring-shadow: 0 0 #0000; /* Default fallback shadow (transparent black) */
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); /* Example default shadow */

  /* font family */
  --font-family: "Plus Jakarta Sans","Nunito", sans-serif;
  --font-weight-100: 100;
  --font-weight-200: 200;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;

  /* primary colors */
  --primary-color: #0057d9;
  --primary-color-b50: #e6f0ff;
  --primary-color-b75: #96c0ff;
  --primary-color-b100: #6ba6ff;
  --primary-color-b200: #2b7fff;
  --primary-color-b300: #0065ff;
  --primary-color-b400: #0047b3;
  --primary-color-b500: #003e9c;

  /* Secondary colors */
  --secondary-color: #0d0c00;
  --secondary-color-b50: #e7e7e6;
  --secondary-color-b100: #b4b4b0;
  --secondary-color-b200: #908f8a;
  --secondary-color-b300: #5d5c54;
  --secondary-color-b400: #3d3d33;
  --secondary-color-b500: #0d0c00;
  --secondary-color-b600: #0c0b00;
  --secondary-color-b700: #090900;
  --secondary-color-b800: #070700;
  --secondary-color-b900: #050500;

  /* error colors */
  --error-color: #cb1e14;
  --error-color-50: #fae9e8;
  --error-color-100: #efb9b6;
  --error-color-200: #e79893;
  --error-color-300: #dc6862;
  --error-color-400: #d54b43;
  --error-color-500: #cb1e14;
  --error-color-600: #b91b12;
  --error-color-700: #90150e;
  --error-color-800: #70110b;
  --error-color-900: #550d08;

  /* success colors */

  --success-color: #084c2e;
  --success-color-50: #e6edea;
  --success-color-100: #b2c8be;
  --success-color-200: #8dad9f;
  --success-color-300: #5a8773;
  --success-color-400: #397058;
  --success-color-500: #084c2e;
  --success-color-600: #07452a;
  --success-color-700: #063621;
  --success-color-800: #042a19;
  --success-color-900: #032013;

  // green used for double ticks #14FF90, https://deeditor.com/
  // warning colors

  --warning-color: #ff991f;
  --warning-color-b50: #fffae6;
  --warning-color-b75: #fff0b3;
  --warning-color-b100: #ffe380;
  --warning-color-b200: #ffc400;
  --warning-color-b300: #ffab00;
  --warning-color-b400: #ff991f;
  --warning-color-b500: #ff8b00;

  /* white color */
  --white-color: #ffffff;
}

body {
  font-family: var(--font-family);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  .atlaskit-portal {
    z-index: 1111111 !important;
  }
}
