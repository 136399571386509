.container {
    display: flex;
    // padding-bottom: 16px;
    padding-inline: 16px;
    flex-direction: column;
    // align-items: center;
    align-self: stretch;
    border-right: 1px solid var(--Gray-cool-100, #EFF1F5);
    background: var(--Base-White, #FFF);
    box-shadow: 5px 0px 25px 0px rgba(94, 92, 154, 0.10);
    height: 100dvh;
    transition: all 300ms;

    .brand_logo_container {
        display: flex;
        padding: 12px 0px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        .brand_logo {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .logo_text {
            color: #0E1726;
            font-family: Nunito;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
        }

        .menu_btn {
            cursor: pointer;
        }
    }


    .side_menu_wrapper {
        display: flex;
        flex-direction: column;

        .side_menu_item_container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 10px;
            align-self: stretch;
            cursor: pointer;
            display: flex;
            padding: 10px;
            align-self: stretch;

            .side_menu_item {
                display: flex;
                gap: 12px;
                flex: 1 0 0;

                .menu_item_text {
                    color: var(--Gray-true-800, #292929);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    // line-height: 20px;
                }

                .arrow_icon {
                    margin-left: auto;
                    display: flex;
                    align-items: center;
                }
            }

            &:hover {
                border-radius: 4px;
                background: #EBEBEB;
            }


        }

        .sub_menu_items_container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;

            .sub_menu_item {
                display: flex;
                padding: 10px 10px 10px 42px;
                align-items: center;
                align-self: stretch;
                border-radius: 4px;
                background: var(--Base-White, #FFF);
                gap: 5px;
                color: var(--Gray-true-800, #292929);
                font-size: 14px;
                font-style: normal;
                font-weight: 500;

                &:hover {
                    color: #1570EF;
                }
            }

        }

        .active_menu_item {
            border-radius: 4px;
            background: #EBEBEB;
        }

        .active_submenu_item {
            // border-radius: 4px;
            background: #1570EF;
        }
    }
}