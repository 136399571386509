.search {
  display: flex;
  padding: 12px 16px;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 6px;
  border: 1px solid #e0e6ed;
  background: #fff;
  outline: none;

  &:focus {
    border: 1px solid var(--Blue-600, #1570ef);
  }
}

/* Inside your style.module.scss file */
.search::-webkit-search-cancel-button {
  display: none;
}

/* For Internet Explorer */
.search::-ms-clear {
  display: none;
}

/* If you've decided to target all search inputs globally, 
you might simplify to input[type='search'] for broader targeting: */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-ms-clear {
  display: none;
}
