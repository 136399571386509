.container {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  max-width: 96%;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    .table_filter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      flex-wrap: wrap;
      gap: 15px;

      .filters_container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 10px;
        align-items: center;
        align-self: stretch;
        flex-wrap: wrap;

      }
    }
  }
}