.container {
    position: relative;

    display: flex;
    padding-top: 24px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    span {
        position: absolute;
        right: 10px;
        top: 30px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
    }

    .card {
        display: flex;
        padding: 20px;
        gap: 20px;
        flex-direction: row;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 6px;
        background: #FFF;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10);

        .left_wrapper {

            img {
                width: 220px;
                height: 220px;
                border-radius: 12px;
            }
        }

        .right_wrapper {
            flex-wrap: wrap;
            display: flex;
            align-items: flex-start;
            gap: 20px;

            .col_1,
            .col_2 {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                align-items: flex-start;
                gap: 15px;

                .property {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 7px;

                    .label {
                        color: var(--Gray-modern-500, #697586);

                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                    }

                    .value {
                        color: var(--Gray-cool-800, #30374F);

                        font-size: 18px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: 28px;
                    }
                }
            }



        }
    }
}

@media screen and (max-width:480px) {
    .container {
        position: relative;

        .card {
            display: block;

            .left_wrapper {
                display: flex;
                justify-content: left;
                margin-bottom: 20px;
            }
        }

        span {
            position: absolute;
            bottom: 15px !important;

        }
    }
}

@media (min-width: 480px) and (max-width: 768px) {
    .container {
        position: relative;

        .card {
            display: block;

            .left_wrapper {
                display: flex;
                justify-content: center;
                margin-bottom: 20px;

            }
        }

        span {
            position: absolute;
            bottom: 15px !important;
        }
    }
}