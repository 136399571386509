.layout_root {
    display: flex;

    .main_wrapper {
        width: 100%;
        transition: all 300ms;
        overflow-x: auto;
        .main_container {

            // background-color: #2467ec;
            // height: 100%;
            // min-height: 100vh;
            // max-height: 100%;
            .content_wrapper {
                display: flex;
                padding: 24px;
                flex-direction: column;
                gap: 20px;
                align-self: stretch;
                background-color: #FBFBFA;
                min-height: calc(100dvh - 107px);
            }
        }


    }

}