.root {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 0px 16px;
    align-self: stretch;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;

    .left_btns_container {
        display: flex;
        min-width: 240px;
        flex-direction: row;
        align-items: center;
        flex: 1 0 0;
        justify-content: flex-end;
        gap: 5px;

        .page_no_container {
            display: flex;
            align-items: flex-start;
            justify-content: center;
        }
    }

    .entries_selector {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        @media screen and (max-width: 768px) {
            font-size: 13px;
        }
    }
}