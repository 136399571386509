.header_container {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    padding: 0.75rem;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
      0px 1px 2px -1px rgba(0, 0, 0, 0.1);

    .left_area {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;

        .heading {
            color: var(--Gray-cool-800, #30374F);

            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: 24px;

            text-transform: capitalize;
        }

        .sub_heading {
            color: var(--Gray-cool-600, #4A5578);

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }

    .right_area {}
}