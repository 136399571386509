.container {
  display: flex;
  padding: 10px 20px;
  gap: 10px;
  align-self: stretch;
  justify-content: space-between;

  background: var(--Base-White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

  transition: all 300ms;

  .left {}

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .profile_container {
      display: flex;
      justify-content: end;

      img {
        width: 39px;
        height: 39px;
        border-radius: 100%;
        flex-shrink: 0;
        cursor: pointer;
      }

      .profile_menu_container {
        position: relative;
      }

      .profile_menubar {
        right: 0px;
        cursor: pointer;
        top: 50px;
        padding: 16px;
        position: absolute;
        transition: all 0.5s;
        width: 180px;
        height: max-content;
        background: var(--Base-White, #fff);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
        z-index: 100;
        /* Ensures sidebar is above other content */
      }

      .collapsed {
        max-width: 0;
        display: none;
        overflow: hidden;
        border-right: 0;
        margin-top: 50px;
        transition: width 0.5s;
        /* Smooth transition for width */
      }

      .top {
        display: flex;
        align-items: center;
        gap: 10px;
        // background-color: #1570EF;
        justify-content: flex-start;

        .pic_container {
          width: 40px;
          height: 40px;
          max-width: 40px;
          border-radius: 6px;
        }

        .details_container {
          display: flex;
          flex-direction: column;
          gap: 0px;

          .name_section {
            display: flex;
            gap: 7.7px;

            .name {
              color: #0e1726;
              font-family: Nunito;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
              /* 150% */
            }

            .tag {
              color: var(--Blue-600, #1570ef);
              font-family: Nunito;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 16px;

              display: flex;
              padding: 0px 4px;
              align-items: flex-start;
              border-radius: 4px;
              background: var(--Blue-50, #eff8ff);
            }
          }

          .email {
            color: rgba(14, 23, 38, 0.6);
            font-family: Nunito;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            /* 142.857% */
          }
        }
      }

      .middle {
        .link {
          text-decoration: none;
        }

        .menu_item {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;

          p {
            color: #0e1726;
            font-family: "Plus Jakarta Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            /* 142.857% */
          }
        }
      }

      .bottom {
        .menu_item {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;

          p {
            color: #e7515a;
            font-family: Nunito;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            /* 142.857% */
          }
        }
      }
    }
  }
}