.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: stretch;
  padding: 1rem;
  border-radius: 6px;
  gap: 20px;
  // background: #fff;
  // box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
  //   0px 1px 2px -1px rgba(0, 0, 0, 0.1);

  .heading {
    color: var(--Gray-cool-800, #30374f);

    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: 14px;
    text-transform: capitalize;
  }
  .sub_heading {
    color: var(--Gray-cool-600, #4a5578);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .btn_container {
    button{
      outline: none;
      border-radius: 2px;
      background: #000000;
      font-size: 17px;
      color: #ffffff;
      font-weight: bold;
      padding: 10px 16px;
      cursor: pointer;
      box-shadow: rgba(67,97,238,0.6) 0px 10px 20px -10px;
    }
  }
}
