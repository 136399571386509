// table.module.scss
.container {
  // width: 100%;
  overflow: auto;
  width: -webkit-fill-available;
  max-height: 550px;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    background-color: transparent !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 100px;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  /* Ensures that cells share borders */
}

thead {
  background: #f6f8fa;
}

th {
  div {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 5px;
    flex: 1 0 0;
    width: max-content;
  }

  // white-space: nowrap;
  border-radius: 5px !important;
  padding: 14px;
  // border-bottom: 1px solid #e5e7eb;
  cursor: pointer;
  color: #0e1726;
  font-size: 14px;
  font-weight: 800;
  position: sticky;
  top: 0;
  background: #f6f8fa;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}

tbody tr:nth-child(even) {
  background-color: #fcfcfc;
  color: var(--table-body-row-color, #4b5563);
}

tbody tr:nth-child(odd) {
  background-color: #ffffff;
  color: #000;
}

td {
  padding: 14px;
  border-bottom: 1px solid #e5e7eb;
  font-size: 14px;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}

tbody tr:hover {
  background-color: #e5e7eb;
  color: #000000;
}

thead th:first-child {
  left: 0;
  z-index: 4;
  /* Highest, so it stays above when scrolling both horizontally and vertically */
  background: #f6f8fa;
}

/* Sticky first column settings */
th:first-child,
td:first-child {
  position: sticky;
  left: 0;
  z-index: 3;
  /* Ensures first column is above normal cells but below the header */
  background-color: #fff;
  /* Match your table's background color */
}