.root {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .container {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;


        @media screen and (max-width: 768px) {
            flex-wrap: wrap;
            // width: 80%;
        }
        .left_wrapper {
            width: 320px;
            max-width: 320px;
            align-self: stretch;
            border-radius: 6px;
            background: #FFF;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10);
            padding: 16px;
            box-sizing: border-box;

            @media screen and (max-width: 768px) {
                // font-size: 13px;
                width: 100%;
                max-width: 100%;
            }
            .items_container {
                display: inline-flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;
                width: 100%;

                .chat_circle {
                    display: flex;
                    width: 32px;
                    height: 32px;
                    padding: 6px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 9999px;
                    background: #F4F4F4;
                }

                .new_chat {
                    display: flex;
                    width: 288px;
                    justify-content: space-between;
                    align-items: center;
                }

                .chat_history {
                    display: flex;
                    flex-direction: column;
                    width: 288px;

                    .chats_side {
                        cursor: pointer;

                        display: flex;
                        padding: 8px;
                        justify-content: flex-start;
                        align-items: center;
                        display: flex;
                        gap: 12px;

                        // text
                        color: var(--black-100, #1C1C1C);

                        font-size: 16px;
                        font-style: normal;
                        font-weight: 350;
                        line-height: 24px;
                        @media screen and (max-width: 768px) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .chat_wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1 0 0;
            align-self: stretch;
            background: #FFF;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10);
            border-radius: 6px;


            .new_chat_home_screen {
                display: flex;
                flex-direction: column;
                align-items: center;
                align-self: stretch;
                gap: 48px;

                .top {
                    padding: 60px 40px;
                    gap: 48px;
                    flex-direction: column;
                    display: flex;
                    box-sizing: border-box;

                    .brand_logo {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 9px;

                        .logo_text {
                            color: #0E1726;
                            font-family: Nunito;
                            font-size: 36.923px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 49.231px;
                            /* 133.333% */
                        }
                    }

                    .cards_container {
                        display: flex;
                        align-items: flex-start;
                        gap: 24px;
                        align-self: stretch;
                        flex-wrap: wrap;
                        
                        .card {
                            display: flex;
                            padding: 16px;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 8px;
                            width: 100%;
                            cursor: pointer;
                            border-radius: 6px;
                            border: 1px solid var(--Gray-true-300, #D6D6D6);
                            background: #FFF;

                            .card_title {
                                color: #171717;

                                font-size: 20px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 30px;
                                @media screen and (max-width: 768px) {
                                    font-size: 16px;
                                }
                            }

                            .card_sub_title {
                                color: var(--Gray-true-500, #737373);

                                font-size: 12px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 18px;
                                @media screen and (max-width: 768px) {
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                }

                .message_input_container {
                    display: flex;
                    padding: 20px 16px;
                    align-items: center;
                    width: 100%;
                    box-sizing: border-box;

                    input {
                        display: flex;
                        padding: 12px 48px 12px 20px;
                        justify-content: center;
                        align-items: center;
                        align-self: stretch;
                        width: inherit;
                        border-radius: 9999px;
                        background: #F4F4F4;
                        border: transparent;

                        // text style
                        color: #6B7280;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        outline: none;
                        font-family: Nunito;
                        letter-spacing: 0.5px;
                        @media screen and (max-width: 768px) {
                            font-size: 12px;
                        }
                    }

                    div {
                        position: absolute;
                        right: 50px;
                        cursor: pointer;

                    }
                }
            }

            .messages_home_screen {
                padding: 16px;
                box-sizing: border-box;
                flex-shrink: 0;
                align-self: stretch;
                gap: 8px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: calc(100vh - 190px);

                .messages_list {
                    gap: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: calc(100vh - 190px);
                    overflow: auto;
                    @media screen and (max-width: 768px) {
                        gap: 0px;
                    }
                    width: -webkit-fill-available;
                   
                    &::-webkit-scrollbar {
                        width: 8px;
                        height: 8px;
                    }

                    &::-webkit-scrollbar-track {
                        background: #ffffff;
                        background-color: transparent !important;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: #d9d9d9;
                        border-radius: 100px;
                    }


                    .chatbot_message_container {
                        display: flex;
                        align-items: flex-start;
                        gap: 12px;
                        width: 75%;
                        @media screen and (max-width: 768px) {
                            width: 80%;
                        }
                       
                        .message_area {
                            display: flex;
                            padding-bottom: 0px;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 8px;
                            @media screen and (max-width: 768px) {
                                gap: 6px;
                            }
                            .message_text_and_emoji {
                                display: flex;
                                align-items: center;
                                align-self: stretch;
                                gap: 12px;
                                cursor: pointer;
                                @media screen and (max-width: 768px) {
                                    gap: 8px;
                                }
                                .message_text {
                                    display: flex;
                                    padding: 8px 16px;
                                    flex-direction: column;
                                    align-items: flex-start;

                                    border-radius: 6px 6px 6px 0px;
                                    background: rgba(14, 23, 38, 0.10);
                                    max-width: 400px;

                                    // text style
                                    color: #0E1726;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 20px;
                                    @media screen and (max-width: 768px) {
                                        font-size: 12px;
                                        padding: 6px 14px;
                                        max-width: 180px;
                                    }
                                }

                            }

                            .message_time {
                                color: #888EA8;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 16px;
                            }
                        }
                    }

                    .row {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;
                        
                    }

                    .user_message_container {

                        display: flex;
                        width: 75%;
                        justify-content: flex-end;
                        align-items: flex-start;
                        gap: 12px;
                        padding-right: 10px;
                       
                        .message_area {
                            display: flex;
                            padding-bottom: 0px;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 8px;

                            .message_text_and_emoji {
                                display: flex;
                                align-items: center;
                                align-self: stretch;
                                gap: 12px;
                                cursor: pointer;

                                .message_text {
                                    display: flex;
                                    padding: 8px 16px;
                                    flex-direction: column;
                                    align-items: flex-start;

                                    border-radius: 6px 6px 0px 6px;
                                    background: #4361EE;

                                    // text style
                                    color: #FFF;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 20px;
                                }

                            }

                            .message_time {
                                color: #888EA8;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 16px;
                            }
                        }
                    }
                }


                .message_input_container {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    box-sizing: border-box;

                    input {
                        display: flex;
                        padding: 12px 48px 12px 20px;
                        justify-content: center;
                        align-items: center;
                        align-self: stretch;
                        width: inherit;
                        border-radius: 9999px;
                        background: #F4F4F4;
                        border: transparent;

                        // text style
                        color: #6B7280;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        outline: none;
                        font-family: Nunito;
                        letter-spacing: 0.5px;
                    }

                    div {
                        position: absolute;
                        right: 50px;
                        cursor: pointer;

                    }
                }
            }
        }
    }
}