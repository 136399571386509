.container {
  width: 56%;
  border-radius: 4px;
  border: 1px dashed #a1a7c4;
  background: #fff;
  padding: 28px;

  .dropArea {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
