.select_container {


    div[role="combobox"] {
        display: flex;
        padding-block: 10.5px;
        align-items: center;
        gap: 10.5px;
        align-self: stretch;

        border-radius: 6px;
        // border: 1px solid var(--input-borderColor, #D1D5DB);
        background: var(--input-background, #FFF);

        // text
        color: var(--input-placeholder-color, #6B7280);
        font-family: "Plus Jakarta Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

    }


}